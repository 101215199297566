
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import './Login.css';
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [errors, setError] = useState({});
  const handleForgotPassword = async () => {
    setError('');

    try {
      const response = await api.post('/forgot-password', { email });

      navigate('/login', { state: { successMessage: response.data.message } });
    } catch (err) {
      if (err.response && err.response.data.errors) {
        setError(err.response.data.errors);
      } else {
        if (err.response && err.response.data.message) {
          toast.error(err.response.data.message, { toastId: 'forgot-password-error-toast' });
        }
        else {
          toast.error("An error occurred. Please try again.", { toastId: 'forgot-password-internal-error-toast' });
        }

      }
    }
  };

  return (
    <div className="login-container">

      <div className="login-box">
        <h2>Forgot Password</h2>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            className='form-control'
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <p className="error-message">{errors.email}</p>}
        </div>
        <button className="login-button btn-primary" onClick={handleForgotPassword}>Send Reset Link</button>
        <div className="separator">
          <span>-----------------------OR-----------------------</span>
        </div>
        <div className="signup-option">
          <a href="/login" className="signup-link">Back to Login</a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
