import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import { toast } from 'react-toastify';
import api from '../../services/api';
import '../../App.css';
import Header from './dashboard_parts/Header';
import FilterSection from './dashboard_parts/FilterSection';
import DashboardCards from './dashboard_parts/DashboardCards';
import Charts from './dashboard_parts/Charts';

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  const [greeting, setGreeting] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [trends, setTrends] = useState({});
  const [productData, setProductData] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState('null');
  const [loading, setLoading] = useState(true);
  const menuRef = useRef(null);

  useEffect(() => {
    const getGreeting = () => {
      const currentHour = new Date().getHours();
      if (currentHour < 12) {
        return 'Good Morning';
      } else if (currentHour < 18) {
        return 'Good Afternoon';
      } else {
        return 'Good Evening';
      }
    };

    setGreeting(getGreeting());

    if (location.state?.successMessage) {
      toast.success(location.state.successMessage, { toastId: 'login-toast' });
      navigate(location.pathname, { replace: true, state: {} });
    }

    const fetchTags = async () => {
      try {
        const response = await api.get('/tags');
        if (response.status === 200) {
          setTags(response?.data?.tags);
        }
      } catch (error) {
        console.error('Error fetching tags:', error);
        toast.error('Failed to load tags.');
      }
    };

    fetchTags();
  }, [location.state, navigate]);

  useEffect(() => {
    const fetchProductData = async () => {
      setLoading(true);
      try {
        const response = await api.get('/products', { params: { tag: selectedTag } });
        if (response.status === 200) {
          setProductData(response?.data?.data);
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
        toast.error('Failed to load product data.');
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, [selectedTag]);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenu = () => setMenuOpen(false);

  const handleOptionClick = (option) => {
    closeMenu();
    if (option === 'Account') navigate('/account?tab=profile');
    else if (option === 'Logout') {
      localStorage.clear();
      navigate('/login', { state: { successMessage: 'Logged out successfully!' } });
    }
  };

  const handleTagChange = (event) => setSelectedTag(event.target.value);
  useEffect(() => {
    if (productData.length > 0) {
      const validProducts = productData.filter(product => product.price !== null && product.price !== undefined);

      if (validProducts.length > 0) {
        const totalPrice = validProducts.reduce((sum, product) => {

          const price = parseFloat(product.price.replace(/[^\d.-]/g, ''));
          return sum + price;
        }, 0);
        const totalOriginalPrice = validProducts.reduce((sum, product) => {

          const originalPrice = parseFloat(product.original_price.replace(/[^\d.-]/g, ''));
          return sum + originalPrice;
        }, 0)

        const avgPrice = (totalPrice / validProducts.length).toFixed(2);
        const avgOriginalPrice = (totalOriginalPrice / validProducts.length).toFixed(2);
        const maxPrice = Math.max(...validProducts.map(product => parseFloat(product.price.replace(/[^\d.-]/g, ''))));
        const minPrice = Math.min(...validProducts.map(product => parseFloat(product.price.replace(/[^\d.-]/g, ''))));

        setTrends({ avgPrice, maxPrice, minPrice, avgOriginalPrice });
      } else {

        setTrends({ avgPrice: '---', maxPrice: '---', minPrice: '---', avgOriginalPrice: '---' });
      }
    }
  }, [productData]);

  const lineData = {
    datasets: [
      {
        label: 'Sale Price vs. Rating',
        data: productData
          .filter(product => product.price !== null && product.average_rating !== null)
          .map(product => ({
            x: product.average_rating,
            y: parseFloat(product.price.replace(/[^\d.-]/g, '')),
          }))
          .sort((a, b) => a.x - b.x),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: 'Sale Price vs. Rating',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const rating = tooltipItem.raw.x;
            const price = tooltipItem.raw.y;

            return `Rating: ${rating}, Price: $${price.toFixed(2)}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: 'linear',
        title: {
          display: true,
          text: 'Average Rating',
        },
        ticks: {
          beginAtZero: false,
          stepSize: 0.1,
        },
      },
      y: {
        type: 'linear',
        title: {
          display: true,
          text: 'Sale Price',
        },
        ticks: {
          beginAtZero: false,
        },
      },
    },
  };

  const truncateName = (name, maxLength = 15) => {
    return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
  };

  const barData = {
    labels: productData.map((product) => truncateName(product.name)),
    datasets: [
      {
        label: 'Total Reviews',
        data: productData.map((product) => product.total_reviews),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        maxBarThickness: 40,
      },
    ],
  };

  const barOptions = {
    indexAxis: 'y',
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          title: function (tooltipItem) {
            if (tooltipItem && tooltipItem.length > 0) {
              const index = tooltipItem[0].dataIndex;
              return productData[index] ? productData[index].name : '';
            }
            return '';
          },
          label: function (tooltipItem) {
            return `Review Count: ${tooltipItem.raw}`;
          },
        },
      },
      title: {
        display: true,
        text: 'Review Count by Product',
      },
      legend: {
        display: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Total Reviews',
        },
        ticks: {
          maxRotation: 45,
          minRotation: 0,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Product Name',
        },
        ticks: {
          beginAtZero: true,
        },
      },
    },
    maxBarThickness: 40,
  };

  const bubbleData = {
    datasets: [
      {
        label: 'Sale Price vs. Review',
        data: productData
          .filter(product => product.price !== null && product.total_reviews !== null)
          .map(product => ({
            x: product.total_reviews,
            y: parseFloat(product.price.replace(/[^\d.-]/g, '')),
            r: 15
          })),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
      },
    ],
  };

  const bubbleOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: 'Sale Price vs. Review',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const reviews = tooltipItem.raw.x;
            const price = tooltipItem.raw.y;

            return `Reviews: ${reviews}, Price: $${price.toFixed(2)}`;
          },
        },
      }
    },
    scales: {
      x: {
        type: 'linear',
        title: {
          display: true,
          text: 'Review',
        },
        ticks: {
          beginAtZero: false,
          stepSize: 1000,
        },
      },
      y: {
        type: 'linear',
        title: {
          display: true,
          text: 'Sale Price',
        },
        ticks: {
          beginAtZero: false,
        },
      },
    },
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-content">
        <Header
          greeting={greeting}
          user={user}
          menuOpen={menuOpen}
          toggleMenu={toggleMenu}
          handleOptionClick={handleOptionClick}
          closeMenu={closeMenu}
          menuRef={menuRef}
        />
        <FilterSection tags={tags} selectedTag={selectedTag} handleTagChange={handleTagChange} />
        <DashboardCards trends={trends} />
        <Charts
          barData={barData}
          barOptions={barOptions}
          lineData={lineData}
          lineOptions={lineOptions}
          bubbleData={bubbleData}
          bubbleOptions={bubbleOptions}
        />
      </div>
    </div>
  );
};

export default Dashboard;
