import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
const SubscriptionCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const message = query.get('message');
    const status = query.get('status');
    if (message && status) {
      if (status === "success") {
        navigate('/plans', { state: { successMessage: message } });
      }
      else {
        navigate('/plans', { state: { errorMessage: message } });
      }
    }
    else {
      navigate('/plans', { state: { errorMessage: "An error occurred. Please try again." } });
    }
  }, [location, navigate]);
};
export default SubscriptionCallback;
