import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import Sidebar from './Sidebar';
import '../../App.css';
import CommonTitle from './CommonTitle';
import SvgIcon from './SvgIcons';
import * as XLSX from 'xlsx';

const SavedProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [records, setTotalRecords] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedFilter, setSelectedFilter] = useState({
    price: '',
    originalPrice: '',
    rating: '',
    reviews: ''
  });

  const PAGE_SIZE = 10;
  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await api.get('/user-products', {
        params: {
          page: currentPage,
          limit: PAGE_SIZE,
          ...selectedFilter,
          search,
        },
      });

      if (response.status === 200) {
        setProducts(response.data.data.data);
        setTotalPages(response.data.data.last_page);
        setTotalRecords(response.data.data.total);
      }
    } catch (error) {
      setError('Error fetching products');
      console.error('Error fetching existing products:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchProducts();
  }, [currentPage, selectedFilter]);


  const handleSearch = () => {
    setCurrentPage(1);
    fetchProducts();
  };
  const handleToggleFavorite = async (productId) => {
    try {
      const response = await api.get(`/products/${productId}/favorite`);
      if (response.status === 200) {
        const updatedProduct = response.data.product;
        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.id === updatedProduct.id ? updatedProduct : product
          )
        );
      } else {
        alert('Failed to update favorite status');
      }
    } catch (error) {
      console.error('Error toggling favorite status:', error);
      alert('An error occurred while updating favorite status');
    }
  };

  const handleFilterChange = (type, value) => {
    setSelectedFilter({
      price: type === 'price' ? value : '',
      originalPrice: type === 'originalPrice' ? value : '',
      rating: type === 'rating' ? value : '',
      reviews: type === 'reviews' ? value : ''
    });
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setSearch('');
    setSelectedFilter({
      price: '',
      originalPrice: '',
      rating: '',
      reviews: ''
    });
    setCurrentPage(1);
    fetchProducts();
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);

    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleDeleteProduct = async (productId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        const response = await api.delete(`/products/${productId}`);
        if (response.status === 200) {
          setProducts(products.filter(product => product.id !== productId));
          alert('Product deleted successfully');
        }
      } catch (err) {
        alert('Error deleting product');
        console.error('Error deleting product:', err);
      }
    }
  };
  const handleExport = () => {
    const ws = XLSX.utils.json_to_sheet(products);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Products');
    XLSX.writeFile(wb, 'products.xlsx');
  };
  if (loading) return <div className='loader-container'><span className="loader"></span></div>;
  if (error) return <p>{error}</p>;

  return (
    <div className="main">
      <Sidebar />
      <div className="main-content">
        <CommonTitle title={'Product Analytics'} />
        <div className='container'>
          <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
            <input
              type="text"
              className='form-control w-100'
              placeholder="Search products..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <span> <button className='btn btn-primary' onClick={handleSearch}>Search</button></span>
            <span><button className='btn btn-primary' onClick={handleResetFilters}>Reset</button></span>
            {products?.length > 0 && records > 0 && (
              <span><button className="btn btn-primary" onClick={handleExport}>Export</button></span>
            )}

          </div>
          <div style={{ display: 'flex', gap: '10px', marginBottom: '20px', flexWrap: 'wrap', justifyContent: 'end' }}>
            <select value={selectedFilter.price} onChange={(e) => handleFilterChange('price', e.target.value)}>
              <option value="">Sort by Sale Price</option>
              <option value="low_to_high">Low to High</option>
              <option value="high_to_low">High to Low</option>
            </select>
            <select value={selectedFilter.originalPrice} onChange={(e) => handleFilterChange('originalPrice', e.target.value)}>
              <option value="">Sort by Original Price</option>
              <option value="low_to_high">Low to High</option>
              <option value="high_to_low">High to Low</option>
            </select>
            <select value={selectedFilter.rating} onChange={(e) => handleFilterChange('rating', e.target.value)}>
              <option value="">Sort by Rating</option>
              <option value="low_to_high">Low to High</option>
              <option value="high_to_low">High to Low</option>
            </select>
            <select value={selectedFilter.reviews} onChange={(e) => handleFilterChange('reviews', e.target.value)}>
              <option value="">Sort by Reviews</option>
              <option value="low_to_high">Low to High</option>
              <option value="high_to_low">High to Low</option>
            </select>
          </div>
          <div className='table-responsive'>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Image</th>
                  <th>Product Name</th>
                  <th>Seller</th>
                  <th>Seller Followers</th>
                  <th>Seller Resources Count</th>
                  <th>Seller Reach Rate</th>
                  <th>Sale Price</th>
                  <th>Original Price</th>
                  <th>Total Reviews</th>
                  <th>Average Rating</th>
                  <th>Potential Sale</th>
                  <th>Customer Satisfaction (%)</th>
                  <th>Grade</th>
                  <th>Subject</th>
                  <th>Format</th>
                  <th>Tags</th>
                  <th>Product URL</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>

                {products?.length > 0 ? (
                  products?.map((product) => (
                    <tr key={product?.id}>

                      <td onClick={() => handleToggleFavorite(product?.id)} style={{ cursor: 'pointer' }}>
                        {product?.favorite == 0 ? (
                          <span>
                            <SvgIcon.StarStroke />
                          </span>
                        ) : (
                          <span>
                            <SvgIcon.StarFill />
                          </span>
                        )}
                      </td>
                      <td>
                        <img
                          src={product?.image}
                          alt={product?.name}
                          style={{ width: '100px', height: '100px' }}
                        />
                      </td>
                      <td>{product?.name}</td>
                      <td>{product?.seller}</td>
                      <td>{product?.seller_followers}</td>
                      <td>{product?.seller_resources_count}</td>
                      <td>
                        {
                          product?.seller_followers && product?.seller_resources_count
                            ? (product?.seller_followers / product?.seller_resources_count).toFixed(2)
                            : 0
                        }
                      </td>
                      <td>{product?.price || 'N/A'}</td>
                      <td>{product?.original_price || 'N/A'}</td>
                      <td>{product?.total_reviews || 'N/A'}</td>
                      <td>{product?.average_rating || 'N/A'}</td>
                      <td>
                        {product?.price
                          ? `$${(parseFloat(product?.price.replace('$', '')) * product?.total_reviews).toFixed(2)}`
                          : 'N/A'}
                      </td>
                      <td>
                        {product?.average_rating
                          ? ((product?.average_rating / 5) * 100).toFixed(2) + '%'
                          : 'N/A'}
                      </td>
                      <td>{product?.grade}</td>
                      <td>{product?.subject}</td>
                      <td>{product?.format}</td>
                      <td>{product?.tags.map((tag, tagIndex) => (
                        (product?.tags?.length > tagIndex + 1) ? tag + ', ' : tag
                      ))}</td>
                      <td>
                        <button
                          className="btn btn-success"
                          onClick={() => window.open(product?.product_url, '_blank', 'noopener,noreferrer')}
                        >
                          View
                        </button>

                      </td>
                      <td>
                        <button className='btn btn-danger' onClick={() => handleDeleteProduct(product?.id)}>Delete</button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="13" className='text-center'>No products found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {products?.length > 0 && records > 0 && (
            <div className="pagination">
              <span> <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                Previous
              </button></span>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <span><button onClick={handleNextPage} disabled={currentPage === totalPages}>
                Next
              </button></span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SavedProducts;
