
import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import '../../App.css';
import CommonTitle from './CommonTitle';
import moment from 'moment';
const Plans = () => {
  const location = useLocation();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userSubscription, setUserSubscription] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [records, setTotalRecords] = useState(0);
  const navigate = useNavigate();
  const fetchUserSubscription = async () => {
    try {
      const response = await api.get('/subscription/user-subscription');
      setUserSubscription(response.data.subscription);
    } catch (error) {
      console.error('Failed to fetch user subscription:', error);
    }
  };

  const fetchTransactions = async (page = 1) => {
    try {
      const response = await api.get(`/transactions?page=${page}`);
      setTransactions(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setTotalPages(response.data.data.last_page);
      setTotalRecords(response.data.data.total);
    } catch (error) {
      console.error('Failed to fetch transactions:', error);
      toast.error('Failed to fetch transactions. Please try again later.', { toastId: 'transaction-error-toast' });
    }
  };
  useEffect(() => {
    if (location.state?.successMessage) {

      toast.success(location.state.successMessage, { toastId: 'subscription-success-toast' });

      navigate(location.pathname, { replace: true, state: {} });
    }
    if (location.state?.errorMessage) {
      toast.error(location.state.errorMessage, { toastId: 'subscription-error-toast' });
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state?.successMessage, location.state?.errorMessage, navigate]);


  useEffect(() => {


    const fetchPlans = async () => {
      try {
        const response = await api.get('/subscription-plans');
        setPlans(response.data.plans);
      } catch (error) {
        console.error('Failed to fetch plans:', error);
        toast.error('Failed to fetch plans. Please try again later.', { toastId: 'subscription-plan-error-toast' });
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
    fetchUserSubscription();
    fetchTransactions();
  }, []);
  const handleCancelSubscription = async (subscriptionId) => {
    const confirmed = window.confirm('Are you sure you want to cancel your subscription?');

    if (confirmed) {
      try {
        const response = await api.post('/subscription/cancel', { subscription_id: subscriptionId });

        if (response.data.success) {
          toast.success('Subscription canceled successfully.', { toastId: 'subscription-cancel-success-toast' });
          fetchUserSubscription();
        } else {
          toast.error('Failed to cancel subscription.', { toastId: 'subscription-cancel-error-toast' });
        }
      } catch (error) {
        console.error('Error during subscription cancellation:', error);
        toast.error('Failed to cancel subscription. Please try again.', { toastId: 'subscription-cancel-internal-error-toast' });
      }
    }
  };

  const handleCheckout = async (planId) => {
    if (userSubscription && !userSubscription.ends_at) {
      const confirmed = window.confirm(
        'You already have an active subscription. Upgrading will cancel your current subscription. Do you want to proceed?'
      );
      if (!confirmed) return;
    }

    try {
      const response = await api.post('/subscription/checkout', { plan_id: planId });
      window.location.href = response.data.sessionUrl;
    } catch (error) {
      console.error('Error during checkout:', error);
      toast.error('Checkout failed. Please try again.', { toastId: 'subscription-checkout-error-toast' });
    }
  };


  const handleNextPage = () => {
    if (currentPage < totalPages) {
      fetchTransactions(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      fetchTransactions(currentPage - 1);
    }
  };

  const formatDescription = (description) => {
    const lines = description.split('\n').filter((line) => line.trim() !== '');
    return (
      <ul>
        {lines.map((line, index) => (
          <li key={index}>{line.includes('•') ? line.replace('•', '') : line}</li>
        ))}
      </ul>
    );
  };

  if (loading) return <div className='loader-container'><span className="loader"></span></div>;

  return (
    <div className="main">
      <Sidebar />
      <div className="main-content">
        <CommonTitle title={'Select a Subscription Plan'} />
        <div className='container'>
          <div className="plans-list">
            {plans.map((plan) => {
              const isSubscribed = userSubscription && userSubscription.stripe_plan_id === plan.stripe_plan_id;
              const startDate = userSubscription?.start_date || '';
              const endDate = userSubscription?.current_period_end || '';
              const renewalDate = userSubscription?.current_period_end ? userSubscription.current_period_end : 'N/A';
              const canceledDate = userSubscription?.ends_at ? `De-Activated on ${moment(userSubscription.ends_at)?.format('MMMM DD, YYYY')}` : null;

              return (
                <div key={plan.id} className="plan-card ">
                  <h2>{plan.name}</h2>
                  <div className="plan-description">{formatDescription(plan.description)}</div>
                  <p>Price: ${plan.price}/{plan.interval}</p>
                  {isSubscribed ? (
                    <div>
                      <p>Start Date: {moment(startDate)?.format('MMMM DD, YYYY')}</p>
                      <p>Current Period Expired Date: {moment(endDate)?.format('MMMM DD, YYYY')}</p>
                      <p>Renewal Date: {canceledDate || moment(renewalDate)?.format('MMMM DD, YYYY')}</p>
                      {!userSubscription?.ends_at && (
                        <button className='btn btn-primary' onClick={() => handleCancelSubscription(userSubscription.id)}>Cancel Subscription</button>
                      )}
                      {!userSubscription?.ends_at ? (
                        <button className='btn btn-outline-primary' onClick={() => handleCheckout(plan.id)}>Upgrade The Subscription</button>
                      ) : (
                        <button className='btn btn-primary' onClick={() => handleCheckout(plan.id)}>Go With Premium</button>
                      )}
                    </div>
                  ) : (
                    <button className='btn btn-primary' onClick={() => handleCheckout(plan.id)}>Go With Premium</button>
                  )}
                </div>
              );
            })}
          </div>
          <h2>Your Transactions</h2>
          <div className="transactions-list">
            {transactions?.length > 0 ? (
              <ul>
                {transactions?.map((transaction) => (
                  <div key={transaction?.id} className="transaction-item">
                    <p>Transaction ID: {transaction?.transaction_id} / Amount: ${transaction?.amount} / Status: {transaction?.status} / Date: {new Date(transaction?.created_at).toLocaleDateString()}</p>
                  </div>
                ))}
              </ul>
            ) : (
              <p>No transactions found.</p>
            )}
          </div>
          {transactions?.length > 0 && records > 0 && (    
            <div className="pagination">
              <span> <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                Previous
              </button></span>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <span><button onClick={handleNextPage} disabled={currentPage === totalPages}>
                Next
              </button></span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Plans;
