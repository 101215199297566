import React from 'react';

const DashboardCards = ({ trends }) => (
  <div className="dashboard-sections">
    <div className="dashboard-card">
      <i className="fas fa-dollar-sign dashboard-icon"></i>
      <h2>Average Price</h2>
      <p>{trends.avgPrice || '---'}</p>
    </div>
    <div className="dashboard-card">
      <i className="fas fa-dollar-sign dashboard-icon"></i>
      <h2>Minimum Price</h2>
      <p>{trends.minPrice || '---'}</p>
    </div>
    <div className="dashboard-card">
      <i className="fas fa-dollar-sign dashboard-icon"></i>
      <h2>Maximum Price</h2>
      <p>{trends.maxPrice || '---'}</p>
    </div>
    <div className="dashboard-card">
      <i className="fas fa-tag dashboard-icon"></i>
      <h2>Average Original Price</h2>
      <p>{trends.avgOriginalPrice || '---'}</p>
    </div>
  </div>
);

export default DashboardCards;
