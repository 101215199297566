import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import './Login.css';
import SvgIcon from './SvgIcons';

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const validateForm = () => {
    const newErrors = {};
    if (!name) newErrors.name = 'Name is required';
    if (!email) newErrors.email = 'Email is required';
    if (!password) newErrors.password = 'Password is required';
    if (password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleGoogleLogin = () => {

    window.location.href = process.env.REACT_APP_GOOGLE_LOGIN_URL;
  };
  const handleRegister = async () => {

    setErrors({});

    if (!validateForm()) return;

    try {
      const response = await api.post('/register', { name, email, password });

      if (response.data.success) {
        navigate('/login', { state: { successMessage: response.data.message } });
      } else {
        toast.error(response.data.message || 'Registration failed', { toastId: 'register-error-toast' });
      }
    } catch (err) {
      if (err.response && err.response.data.errors) {
        setErrors(err.response.data.errors);
      } else {
        toast.error('An error occurred. Please try again.', { toastId: 'register-internal-error-toast' });
      }
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className='auth-logo'><SvgIcon.Logo /></div>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            className='form-control'
            placeholder="Type your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name && <p className="error-message">{errors.name}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            className='form-control'
            id="email"
            placeholder="Type your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <p className="error-message">{errors.email}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className='form-control'
            id="password"
            placeholder="Type your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errors.password && <p className="error-message">{errors.password}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            className='form-control'
            type="password"
            id="confirmPassword"
            placeholder="Confirm your password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {errors.confirmPassword && <p className="error-message">{errors.confirmPassword}</p>}
        </div>

        <button className="login-button btn-primary" onClick={handleRegister}>
          Register
        </button>

        <div className="separator">
          <span>-----------------------OR-----------------------</span>
        </div>
        <div className="alternative-options">
          <button className="google-login-button btn-primary" onClick={handleGoogleLogin}>
            <img
              src="https://developers.google.com/identity/images/g-logo.png"
              alt="Google Icon"
            />
            Continue with Google
          </button>
        </div>
        <div className="signup-option">
          <p className='fs-14'>Already have an account?</p>
          <a href="/login" className="signup-link">Login</a>
        </div>
      </div>
    </div>
  );
};

export default Register;
