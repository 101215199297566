import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import './Login.css';
import { useAuth } from '../../AuthContext';
import SvgIcon from './SvgIcons';

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const redirectTo = searchParams.get('redirect_to');
  useEffect(() => {
    if (location.state?.successMessage) {
      toast.success(location.state.successMessage, { toastId: 'login-success-toast' });
      navigate(location.pathname, { replace: true, state: {} });
    }
    if (location.state?.errorMessage) {
      toast.error(location.state.errorMessage, { toastId: 'login-error-toast' });
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state, navigate]);



  const { setIsAuthenticated } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    if (!email || !password) {
      setErrors({
        email: !email ? 'Email is required' : '',
        password: !password ? 'Password is required' : '',
      });
      return false;
    }
    return true;
  };

  const handleLogin = async () => {
    setErrors({});

    if (!validateForm()) return;

    try {
      const response = await api.post('/login', { email, password });

      if (response.data.success) {

        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        localStorage.setItem('access_token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        setIsAuthenticated(true);
        if (redirectTo) {

          window.location.href = redirectTo + "?access_token=" + response.data.token + "&user=" + JSON.stringify(response.data.user);
        } else {

          navigate('/dashboard', { state: { successMessage: 'Login successful!' } });
        }
      } else {
        setIsAuthenticated(false);
        toast.error(response.data.message || 'Invalid credentials', { toastId: 'invalid-credential-toast' });
      }
    } catch (err) {
      if (err.response && err.response.data.errors) {
        setErrors(err.response.data.errors);
      } else {
        toast.error("An error occurred. Please try again.", { toastId: 'login-internal-error-toast' });
      }
    }
  };

  const handleGoogleLogin = () => {
    const googleLoginBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_URL;

    const googleLoginUrl = redirectTo
      ? `${googleLoginBaseUrl}?redirect_to=${encodeURIComponent(redirectTo)}`
      : googleLoginBaseUrl;
    window.location.href = googleLoginUrl;
  };

  return (
    <div className="login-container">

      <div className="login-box">
        <div className='auth-logo'><SvgIcon.Logo /></div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            id="email"
            className='form-control'
            placeholder="Type your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <p className="error-message">{errors.email}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            className='form-control'
            placeholder="Type your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errors.password && <p className="error-message">{errors.password}</p>}
        </div>
        <button className="login-button btn-primary" onClick={handleLogin}>
          Login
        </button>
        <div className="separator">
          <span>-----------------------OR-----------------------</span>
        </div>
        <div className="alternative-options">
          <button className="google-login-button btn-primary" onClick={handleGoogleLogin}>
            <img
              src="https://developers.google.com/identity/images/g-logo.png"
              alt="Google Icon"
            />
            Continue with Google
          </button>
          <a href="/forgot-password" className="forgot-password-link">
            Forgot Password?
          </a>
        </div>
        <div className="signup-option">
          <p className='fs-14'>Don't have an account?</p>
          <a href="/register" className="signup-link">Sign Up</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
