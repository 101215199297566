export const configuration = {
    
    // FRONTEND_BASE_URL : "http://localhost:3000",
    // BACKEND_BASE_URL :"http://127.0.0.1:8000/api",

    FRONTEND_BASE_URL : "https://tptgenie-fe.itechnolabs.tech",
    BACKEND_BASE_URL :"https://tptgenie-be.itechnolabs.tech/api",
    EXTENSION_SITE_URL :"https://www.teacherspayteachers.com",

}
