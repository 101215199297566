import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import CommonTitle from './CommonTitle';
import Sidebar from './Sidebar';
import api from '../../services/api';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TagPerformanceChart = () => {
    const [tagCounts, setTagCounts] = useState({});
    const [loading, setLoading] = useState(true);


    const fetchTags = async () => {
        try {
            const response = await api.get('/tag-usage');
            if (response.status === 200) {
                setTagCounts(response.data.tags);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching tag data:', error);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchTags();
    }, []);
    const chartData = {
        labels: Object.keys(tagCounts),
        datasets: [
            {
                label: 'Tags',
                data: Object.values(tagCounts),
                backgroundColor: "rgba(0,180,211,255)",
                borderColor: 'rgb(100,228,252)',
                borderWidth: 1,
                maxBarThickness: 40,
            },
        ],
    };
    const options = {
        responsive: true,
        indexAxis: 'y',
        plugins: {
            title: {
                display: true,
                text: 'Most Frequent Tags',
            },
            tooltip: {
                callbacks: {
                    label: (context) => `Count: ${context.raw}`,
                },
            },
            legend: {
                display: true,
            },
        },
        scales: {
            x: {
                ticks: {
                    maxRotation: 45,
                    minRotation: 0,
                },
            },
            y: {
                ticks: {
                    beginAtZero: true,
                },
            },
        }
    };

    return (
        <div className="main">
            <Sidebar />
            <div className="main-content">
                <CommonTitle title={'Most Frequent Tags'} />
                <div className='container'>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <div className='common-card'> <Bar data={chartData} options={options} /></div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TagPerformanceChart;
