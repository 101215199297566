import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../App.css';
import SvgIcon from './SvgIcons';

const Sidebar = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleSidebar = () => setSidebarToggle(!sidebarToggle);
  const location = useLocation(); // Get the current location

  const handleLogout = () => {
    localStorage.removeItem('access_token'); // Remove token
    localStorage.removeItem('user'); // Remove user details
    navigate('/login', { state: { successMessage: 'Logged out successfully!' } });
  };

  const handleNavigateToHome = () => {
    navigate('/dashboard');
  };

  const handleNavigateToAccount = () => {
    navigate('/account?tab=salesChannel');
  };
  const handleNavigateToPLans = () => {
    navigate('/plans');
  };
  const handleNavigateToSavedProducts = () => {
    navigate('/saved-products');
  }
  const handleNavigateToFavoriteProducts = () => {
    navigate('/favorite-products');
  }
  const handleNavigateToTagsAnalytics = () => {
    navigate('/tag-analytics');
  }
  const handleNavigateToTagPerformance = () => {
    navigate('/tag-performance');
  }
  const handleNavigateToTopSellers = () => {
    navigate('/top-sellers');
  }

  // Function to check if the current path is active
  const isActive = (path) => location.pathname === path;

  return (
    <div className={`sidebar ${sidebarToggle ? 'hide' : ''}`}>
      <div className="sidebar-menu">
        <ul className='sidebar-menu-inner'>
          <div>
            <button className='toggle-btn' onClick={toggleSidebar}><SvgIcon.Menu /></button>
            <div className="sidebar-header">
              {!sidebarToggle ? <SvgIcon.Logo /> : <div><SvgIcon.LogoSmall /></div>}
            </div>

            {!sidebarToggle && <div className='accordian-btn' onClick={toggleMenu}>
              <span>Menu</span>
              <SvgIcon.ChevronDown />
            </div>}
            {!menuOpen && <div className='submenu'>
              <li className={isActive('/dashboard') ? 'active' : ''} onClick={handleNavigateToHome}>
                <SvgIcon.Home />
                {!sidebarToggle && <span>Home</span>}
              </li>
              <li className={isActive('/account') ? 'active' : ''} onClick={handleNavigateToAccount}>
                <SvgIcon.Account />
                {!sidebarToggle && <span>Account</span>}
              </li>
              <li className={isActive('/saved-products') ? 'active' : ''} onClick={handleNavigateToSavedProducts}>
                <SvgIcon.ProductAnalystic />
                {!sidebarToggle && <span>Product Analytics</span>}

              </li>

              <li className={isActive('/favorite-products') ? 'active' : ''} onClick={handleNavigateToFavoriteProducts}>
                <SvgIcon.SavedProduct />
                {!sidebarToggle && <span>Favorite Products</span>}

              </li>
              <li className={isActive('/tag-analytics') ? 'active' : ''} onClick={handleNavigateToTagsAnalytics}>
                <SvgIcon.Tag />
                {!sidebarToggle && <span>Most Frequent Tags</span>}

              </li>
              <li className={isActive('/tag-performance') ? 'active' : ''} onClick={handleNavigateToTagPerformance}>
                <SvgIcon.Performance />
                {!sidebarToggle && <span>Tags Performance</span>}
              </li>
              <li className={isActive('/top-sellers') ? 'active' : ''} onClick={handleNavigateToTopSellers}>
                <SvgIcon.Seller />
                {!sidebarToggle && <span>Top Sellers</span>}
              </li>
              <li className={isActive('/plans') ? 'active' : ''} onClick={handleNavigateToPLans}>
                <SvgIcon.Plans />
                {!sidebarToggle && <span>Plans</span>}
              </li>
            </div>}
          </div>
          <li className='mt-auto logout' onClick={handleLogout}>
            <SvgIcon.Logout />
            {!sidebarToggle && <span>Logout</span>}
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Sidebar;
