// App.js
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Dashboard from './components/pages/Dashboard';
import Login from './components/pages/Login';
import ProtectedRoute from './components/pages/ProtectedRoute';
import OAuthCallback from './components/pages/OAuthCallback';
import Register from './components/pages/Register';
import ForgotPassword from './components/pages/ForgotPassword';
import ResetPassword from './components/pages/ResetPassword';
import Account from './components/pages/Account';
import Plan from './components/pages/Plan';
import SubscriptionCallback from './components/pages/SubscriptionCallback';
import SavedProducts from './components/pages/SavedProducts';
import FavoriteProducts from './components/pages/FavoriteProducts';
import { ToastContainer } from 'react-toastify';
import TagPerformanceChart from './components/pages/TagPerformance';
import TagPerformanceList from './components/pages/TagPerformanceList';
import TopSeller from './components/pages/TopSellers';
import 'react-toastify/dist/ReactToastify.css';
import EmailVerificationCallback from './components/pages/EmailVerificationCallback';

function App() {
  return (
    <AuthProvider>
      <Router>
      <ToastContainer />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verification-status" element={<EmailVerificationCallback/>} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} /> 
          <Route path="/reset-password" element={<ResetPassword />} /> 
          <Route path="/auth-callback" element={<OAuthCallback />} />
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/tag-analytics" element={<ProtectedRoute><TagPerformanceChart /></ProtectedRoute>} />
          <Route path="/Account" element={<ProtectedRoute><Account /></ProtectedRoute>} />
          <Route path="/plans" element={<ProtectedRoute><Plan/></ProtectedRoute>} />
          <Route path="/saved-products" element={<ProtectedRoute><SavedProducts/></ProtectedRoute>} />
          <Route path="/favorite-products" element={<ProtectedRoute><FavoriteProducts/></ProtectedRoute>} />
          <Route path="/subscription-status" element={<ProtectedRoute><SubscriptionCallback/></ProtectedRoute>} />
          <Route path="/tag-performance" element={<ProtectedRoute><TagPerformanceList /></ProtectedRoute>} />
          <Route path="/top-sellers" element={<ProtectedRoute><TopSeller /></ProtectedRoute>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;


