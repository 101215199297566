import React from 'react';

const FilterSection = ({ tags, selectedTag, handleTagChange }) => (
  <div className="filter-section">
    <label htmlFor="tagSelect">Filter by Tag: </label>
    <select id="tagSelect" onChange={handleTagChange} value={selectedTag || ''}>
      <option value="null">No Tag Assigned</option>
      {tags.map((tag, index) => (
        <option key={index} value={tag}>
          {tag}
        </option>
      ))}
    </select>
  </div>
);

export default FilterSection;
