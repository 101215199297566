import React from 'react';
import { Bar, Line, Bubble } from 'react-chartjs-2';
import {
    Chart,
    BarElement,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
  } from "chart.js";
  
 
  Chart.register(
    BarElement,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
  );
  

const Charts = ({ barData, barOptions, lineData, lineOptions, bubbleData, bubbleOptions }) => (
  <div className="charts">
   <div className="chart-section common-card">
                <h3>Sale Price vs. Rating(Value for Money)</h3>
                <p>This line chart illustrates the relationship between product prices and their average ratings. It provides insights into whether higher-priced products tend to receive better ratings, helping you assess the balance between pricing and customer satisfaction.</p>

                <div className="chart-container">
                  <div className="line-chart table-responsive">
                    <Line data={lineData} options={lineOptions} />
                  </div>
                </div>
                <small>Products with both high ratings and competitive prices are likely to resonate more with customers, making this balance crucial for optimizing sales performance.</small>
              </div>

              <div className="chart-section common-card">
                <h3>Review Count by Product</h3>
                <p>This bar chart shows the number of reviews for each product, giving insights into customer engagement. Products with more reviews may indicate higher interest or trust from customers.</p>
                <div className="chart-container">
                  <div className="bar-chart">
                    <Bar data={barData} options={barOptions} />
                  </div>
                </div>
                <small>Products with more reviews are likely to be trusted more by customers. If your product has fewer reviews, consider strategies to encourage customer feedback.</small>
              </div>

              <div className="chart-section common-card">
                <h3>Sale Price vs Reviews(Value for Money) </h3>
                <p>This bubble chart visualizes the relationship between product sale prices and their reviews. The size of the bubbles represents additional product attributes, such as popularity or sales volume. </p>
                <div className="chart-container">
                  <div className="bubble-chart">
                    <Bubble data={bubbleData} options={bubbleOptions} />
                  </div>
                </div>
                <small>This visualization helps identify trends, such as whether higher-priced products tend to receive better reviews, enabling data-driven decisions to balance pricing with customer satisfaction.</small>
              </div>
  </div>
);

export default Charts;
