import React from 'react';

const Header = ({ greeting, user, menuOpen, toggleMenu, handleOptionClick, closeMenu, menuRef }) => (
  <div className="header">
    <h1 className="m-0">{greeting}, {user?.name}</h1>
    <div className="user-info" onClick={toggleMenu} ref={menuRef}>
      <div className="user-circle">{user?.name.charAt(0).toUpperCase()}</div>
      <span className="user-name">{user?.name}</span>
      <span className={`arrow-icon ${menuOpen ? 'up' : 'down'}`}>
        <i className="fas fa-chevron-down"></i>
      </span>
      {menuOpen && (
        <div className="options-menu">
          <div className="user-details">
            <p className="user-name">{user?.name}</p>
            <p className="user-email">{user?.email}</p>
          </div>
          <div className="option" onClick={() => handleOptionClick('Account')}>Account</div>
          <div className="option" onClick={() => handleOptionClick('Logout')}>Logout</div>
        </div>
      )}
    </div>
  </div>
);

export default Header;
