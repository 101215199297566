import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../AuthContext';
const OAuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const user = query.get('user');
    const redirectTo = query.get('redirect_to');
    const status = query.get('status');
    const message = query.get('message');
    if (token && user) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('user');
      localStorage.setItem('access_token', token);
      localStorage.setItem('user', user);
      setIsAuthenticated(true);
      if (redirectTo) {
        window.location.href = redirectTo + "?access_token=" + token + "&user=" + user;
      }
      navigate('/dashboard', { state: { successMessage: 'Login successful!' } });
    }
    else if(status === "error"  && message){
      navigate('/login', { state: { errorMessage: message } });
    }
    else {
      setIsAuthenticated(false);
      navigate('/login', { state: { errorMessage: "An error occurred. Please try again." } });
    
    }
  }, [setIsAuthenticated, location, navigate]);


};
export default OAuthCallback;
