import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import '../../App.css';
import api from '../../services/api';
import CommonTitle from './CommonTitle';

const Account = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get('tab') || 'profile';

  const [activeTab, setActiveTab] = useState(initialTab);
  useEffect(() => {

    setActiveTab(initialTab);
  }, [initialTab]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);

    navigate(`?tab=${tab}`, { replace: true });
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-content">

        <CommonTitle title={'Account'} />
        <div className='tab-container'>
          <div className="tabs">
            <button
              className={activeTab === 'salesChannel' ? 'active' : ''}
              onClick={() => handleTabChange('salesChannel')}
            >
              Sales Channel
            </button>
            <button
              className={activeTab === 'profile' ? 'active' : ''}
              onClick={() => handleTabChange('profile')}
            >
              Profile
            </button>
          </div>


          <div className="tab-content common-card">
            {activeTab === 'profile' && <ProfileTab />}
            {activeTab === 'salesChannel' && <SalesChannelTab />}
          </div>
        </div>
      </div>
    </div>
  );
};


const ProfileTab = () => {
  const user = JSON.parse(localStorage.getItem('user')) || {
    name: 'SuperAdmin',
    email: 'superadmin@example.com',
  };

  return (
    <div className="profile-tab">
      <form className="profile-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            value={user.name}
            disabled
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={user.email}
            disabled
            className="form-control"
          />
        </div>
      </form>
    </div>
  );
};

const SalesChannelTab = () => {
  const [salesChannelData, setSalesChannelData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSalesChannelData = async () => {
      try {
        const response = await api.get('sales-channel');

        if (response.data) {
          setSalesChannelData(response?.data?.data);
        } else {
          setSalesChannelData(null);
        }
      } catch (error) {
        console.error("Error fetching sales channel data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSalesChannelData();
  }, []);

  return (
    <div className="sales-channel-tab">
      {loading ? (
        <div className='loader-container'><span className="loader"></span></div>
      ) : salesChannelData ? (
        <div className="sales-channel-info">
          <div className="form-group">
            <label htmlFor="user_name">Username</label>
            <input
              type="text"
              id="user_name"
              value={salesChannelData.user_name || ''}
              disabled
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={salesChannelData.email || ''}
              disabled
              className="form-control"
            />
          </div>
        </div>
      ) : (
        <p>No channel found.</p>
      )}

    </div>
  );
};

export default Account;
