import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import Sidebar from './Sidebar';
import '../../App.css';
import CommonTitle from './CommonTitle';
import * as XLSX from 'xlsx';

const TagPerformanceList = () => {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [records, setTotalRecords] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedFilter, setSelectedFilter] = useState({
    useCount: '',
    rating: '',
    reviews: ''
  });
  const PAGE_SIZE = 10;
  const fetchTags = async () => {
    try {
      setLoading(true);
      const response = await api.get('/tag-performance', {
        params: {
          page: currentPage,
          limit: PAGE_SIZE,
          ...selectedFilter,
          search,
        },
      });

      if (response.status === 200) {
        setTags(response.data.tag_performance.data);
        setTotalPages(response.data.tag_performance.last_page);
        setTotalRecords(response.data.tag_performance.total);
      }
    } catch (error) {
      setError('Error fetching tags');
      console.error('Error fetching existing tags:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTags();
  }, [currentPage, selectedFilter]);

  const handleSearch = () => {
    setCurrentPage(1);
    fetchTags();
  };

  const handleFilterChange = (type, value) => {
    setSelectedFilter({
      useCount: type === 'useCount' ? value : '',
      rating: type === 'rating' ? value : '',
      reviews: type === 'reviews' ? value : ''
    });
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setSearch('');
    setSelectedFilter({
      useCount: '',
      rating: '',
      reviews: ''
    });
    setCurrentPage(1);
    fetchTags();
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);

    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleExport = () => {
    const ws = XLSX.utils.json_to_sheet(tags);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Tags');


    XLSX.writeFile(wb, 'tags.xlsx');
  };
  if (loading) return <div className='loader-container'><span className="loader"></span></div>;
  if (error) return <p>{error}</p>;

  return (
    <div className="main">
      <Sidebar />
      <div className="main-content">
        <CommonTitle title={'Tags Performance'} />
        <div className='container'>
          <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
            <input
              type="text"
              className='form-control w-100'
              placeholder="Search tags..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <span> <button className='btn btn-primary' onClick={handleSearch}>Search</button></span>
            <span><button className='btn btn-primary' onClick={handleResetFilters}>Reset</button></span>
            {tags?.length > 0  && records > 0 && (
              <span><button className="btn btn-primary" onClick={handleExport}>Export</button></span>
            )}
          </div>
          <div style={{ display: 'flex', gap: '10px', marginBottom: '20px', flexWrap: 'wrap', justifyContent: 'end' }}>
            <select value={selectedFilter.useCount} onChange={(e) => handleFilterChange('useCount', e.target.value)}>
              <option value="">Sort by Use Count</option>
              <option value="low_to_high">Low to High</option>
              <option value="high_to_low">High to Low</option>
            </select>

            <select value={selectedFilter.rating} onChange={(e) => handleFilterChange('rating', e.target.value)}>
              <option value="">Sort by Rating</option>
              <option value="low_to_high">Low to High</option>
              <option value="high_to_low">High to Low</option>
            </select>

            <select value={selectedFilter.reviews} onChange={(e) => handleFilterChange('reviews', e.target.value)}>
              <option value="">Sort by Reviews</option>
              <option value="low_to_high">Low to High</option>
              <option value="high_to_low">High to Low</option>
            </select>
          </div>
          <div className='table-responsive'>
            <table>
              <thead>
                <tr>
                  <th>Tag Name</th>
                  <th>Use Count</th>
                  <th>Average Rating</th>
                  <th>Average Reviews</th>
                  <th>Customer Satisfaction (%)</th>
                </tr>
              </thead>
              <tbody>

                {tags?.length > 0 ? (
                  tags?.map((tag) => (
                    <tr key={tag?.id}>
                      <td>{tag?.tag}</td>
                      <td>{tag?.use_count}</td>
                      <td>{tag?.average_rating || 'N/A'}</td>
                      <td>{tag?.average_reviews || 'N/A'}</td>
                      <td>
                        {tag?.average_rating
                          ? ((tag?.average_rating / 5) * 100).toFixed(2) + '%'
                          : 'N/A'}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="13" className='text-center'>No tags found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {tags?.length > 0 && records > 0 && (
            <div className="pagination">
              <span> <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                Previous
              </button></span>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <span><button onClick={handleNextPage} disabled={currentPage === totalPages}>
                Next
              </button></span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default TagPerformanceList;
